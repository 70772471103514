import React, {Component} from 'react';
import CanvasJSReact from '../assets/canvasjs.react';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class PieChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    render() {
        let {addresses, total_balance, asset} = this.props;

        let sum = 0;
        for (let ind in addresses) {
            sum += addresses[ind].amount;
        }

        let data = [];
        for (let ind in addresses) {
            data.push({
                y: Math.floor(addresses[ind].amount / sum * 100, 2),
                label: addresses[ind].address,
                amount: addresses[ind].amount,
                asset: asset
            });
        }


        const options = {
            exportEnabled: true,
            animationEnabled: true,
            title: {
                text: `Total balance: ${total_balance} ${asset}`
            },
            data: [{
                type: "pie",
                startAngle: 60,
                radius: 100,
                animate: true,
                toolTipContent: "<b>{y}%</b>",
                indexLabel: "{label} - {amount} {asset}",
                dataPoints: data
            }]
        };

        return (
            <div>
                <h1>Chart of addresses</h1>
                <CanvasJSChart options={options} addresses={addresses}
                />
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }
}

export default PieChart;