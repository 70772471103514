import React, {Component} from "react";

import {
    Link
} from 'react-router-dom'

import {Table} from "antd";
import Asset from "./Asset"

import {Apis} from "bitsharesjs-ws";
import {ChainStore, FetchChain} from "bitsharesjs";
import BigNumber from "bignumber.js"

import axios from "axios";

//var gate_api_root = "https://gateway.rudex.org/api/rudex_new_test";


var gate_api_root = "https://gateway.rudex.org/api/rudex_new";
var gatewayApi = gate_api_root + "/coins";
var balanceApi = gate_api_root + "/wallets/";


var gate_api_bep20 = "https://gateway.rudex.org/api/rudex_new_bep20";
var gatewayApi_bep20 = gate_api_bep20 + "/coins";
var balanceApi_bep20 = gate_api_bep20 + "/wallets/";

//const gatewayApi = "http://localhost:3001/api/rudex/coins";
//const balanceApi = "http://localhost:3001/api/rudex/wallets/";

//const configUrl = "https://config.blckchnd.com/balances.json?" + Date.now();
const configUrl = "https://files.rudex.org/config/config-audit.json";

const CONFIG_RUDEX_URL = "https://market.rudex.org/#";

//======================================================================================
//1 - Graphene


const GraphenChains = {
 /*   "RUDEX.BTC": {
        "hot": 0.1,
        "blockchain": "bitcoin",
        "type": "list",
        "list_addresses": balanceApi + "bitcoin/list_addresses"

    },*/
    "RUDEX.LTC": {
        "hot": 0.1,
        "blockchain": "litecoin",
        "type": "list",
        "list_addresses": balanceApi + "litecoin/list_addresses"

    },
/*    "RUDEX.ETH": {
        "hot": 1,
        "blockchain": "ethereum",
        "type": "simple",
        /!*"link": "https://etherscan.io/address/0x3fac105bb57c6ec28603cdc644762472acb9548c",
        "cold_address": "0x3fac105bb57c6ec28603cdc644762472acb9548c"*!/
        "link": "https://etherscan.io/address/0xfA862882a1c9f1D5109AD9e71CB14daa7fD106A7",
        "cold_address": "0xfA862882a1c9f1D5109AD9e71CB14daa7fD106A7"

    },*/

    //OLD ERC-20
    /*    "RUDEX.USDT": {
            "hot": 100,
            "blockchain": "eth-tokens/usdt",
            "type": "simple",
            "link": "https://etherscan.io/address/0x3fac105bb57c6ec28603cdc644762472acb9548c#tokentxns",
            "cold_address": "0x3fac105bb57c6ec28603cdc644762472acb9548c",

        },*/
    "RUDEX.USDT": {
        "hot": 1,
        "blockchain": "usdt",
        "type": "simple",
        "link": "https://tronscan.io/#/address/TEvSHwWd8FyrM6j4XJpnjmtYmrSS9MkGDu",
        "cold_address": "TEvSHwWd8FyrM6j4XJpnjmtYmrSS9MkGDu"

    },
    "RUDEX.XMR": {
        "hot": 100,
        "blockchain": "monero",
        "type": "private",
        "cold_address": "PRIVATE",

    },
    /*    "RUDEX.GRC": {
            "hot": 500,
            "blockchain": "gridcoin",
            "type":"list",
            "list_addresses": balanceApi + "gridcoin/list_addresses"

        },*/

    "RUDEX.EOS": {
        "hot": 50,
        "blockchain": "eos",
        //"link": "https://www.eosx.io/account/{account}"
        "type": "simple",
        "link": "https://bloks.io/account/{account}"
    },
    /*    "RUDEX.PPY": {
            "hot": 100,
            "blockchain": "peerplays",
            "type": "simple",
            "link": "https://peerplaysblockchain.info/account/{account}",
            //"link2": "https://peerplaysdb.com/@{account}"
        },*/

    "RUDEX.STEEM": {
        "hot": 100,
        "blockchain": "steem",
        "type": "simple",
        "link": "https://steemd.com/@{account}"
    },
    /*    "RUDEX.SBD": {
            "hot": 100,
            "blockchain": "sbd",
            "type":"simple",
            "link": "https://steemd.com/@{account}"
        },

        "RUDEX.KRM": {
            "hot": 50000,
            "blockchain": "karma",
            "type":"simple",
            "link": "https://krm-db.com/@{account}"
        },*/

    "RUDEX.GOLOS": {
        "hot": 10000,
        "blockchain": "golos",
        "type": "simple",
        "link": "https://golos.id/@{account}/transfers",
    },

    /*    "RUDEX.GBG": {
            "hot": 100,
            "blockchain": "gbg",
            "type":"simple",
            "link": "https://golos.id/@{account}/transfers",
        },*/

    /*    "RUDEX.WLS": {
            "hot": 10000,
            "blockchain": "whaleshares",
            "type": "simple",
            "link": "https://explorer.whaleshares.io/#/account/{account}"
        },
        "RUDEX.SMOKE": {
            "hot": 1000,
            "blockchain": "smoke",
            "type": "simple",
            "link": "https://smoke.io/@{account}/transfers"
        },*/
    "RUDEX.PZM": {
        //"hot": 1000,
        //"blockchain": "prizm",
        //"link": "http://blockchain.prizm.space",
        "link": "https://prizm.network/{account}",

        "addresses": [
            "PRIZM-7QL2-5CPL-NHMA-65FVR",
            /*            "PRIZM-8REA-RHY9-7YU7-PBZLJ",
                        "PRIZM-TG96-B4CV-HMEM-67NLG",
                        "PRIZM-TXJG-KPGZ-T3TW-D64MK",
                        "PRIZM-8SXU-SCYW-JGL8-MLZW9",
                        "PRIZM-SEMM-JY6M-JUSW-8SABZ",
                        "PRIZM-8FKG-V5NU-LR5H-ZEN97",
                        "PRIZM-B2Z2-Q98U-WKE3-5J77K",*/
        ],

        //"hot_address": "PRIZM-7QL2-5CPL-NHMA-65FVR"
        //"cold_address": "PRIZM-8REA-RHY9-7YU7-PBZLJ"

        "blockchain": "prizm",
        "type": "simple",
        //"list_addresses": balanceApi + "prizm/list_addresses"
    },
/*    "RUDEX.NBS": {
        "hot": 1000,
        "blockchain": "nbs",
        "type": "simple",
        "link": "https://explorer.nbs.plus/details/account/{account}"
    },*/
    "RUDEX.BTS": {
        "hot": 1000,
        "blockchain": "bts",
        "type": "simple",
        "link": "https://bts.ai/details/account/{account}"
    },
    "RUDEX.TRX": {
        "hot": 500,
        "blockchain": "tron",
        "type": "simple",
        "link": "https://tronscan.io/#/address/TEvSHwWd8FyrM6j4XJpnjmtYmrSS9MkGDu",
        "cold_address": "TEvSHwWd8FyrM6j4XJpnjmtYmrSS9MkGDu"

    },
    "RUDEX.DEC": {
        "hot": 500,
        "blockchain": "bsc-tokens",
        "type": "simple",
        "link": "https://bscscan.com/address/0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7",
        "cold_address": "0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7"

    },
    "RUDEX.SPS": {
        "hot": 500,
        "blockchain": "bsc-tokens",
        "type": "simple",
        "link": "https://bscscan.com/address/0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7",
        "cold_address": "0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7"

    },
    "RUDEX.BNB": {
        "hot": 500,
        "blockchain": "bsc-tokens",
        "type": "simple",
        "link": "https://bscscan.com/address/0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7",
        "cold_address": "0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7"

    },
/*    "RUDEX.BUSD": {
        "hot": 500,
        "blockchain": "bsc-tokens",
        "type": "simple",
        "link": "https://bscscan.com/address/0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7",
        "cold_address": "0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7"

    },*/
    "RUDEX.BTCB": {
        "hot": 500,
        "blockchain": "bsc-tokens",
        "type": "simple",
        "link": "https://bscscan.com/address/0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7",
        "cold_address": "0xfa862882a1c9f1d5109ad9e71cb14daa7fd106a7"

    },

};


const coreToken = {
    id: "1.3.0",
    symbol: "GPH",
    precision: 5
};

let config;
let monitor = [];
let warnMinAmount = BigNumber(1);
let okAccumulatedFees = BigNumber(100);
let warnAccountBalance = BigNumber(50);
let warnPoolBalance = BigNumber(50);
let okAccountBalance = BigNumber(100);
let okPoolBalance = BigNumber(100);


class Balances extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            data1: [],
            data2: [],
            data3: [],
            prices: {},
            loading: true
        }
    }

    componentDidMount() {
        this.start();
    }

    start() {
        axios.get(configUrl)
            .then((response) => {
                config = response.data;

                //accounts2: G5 + Fund
                //accounts3: Bots: blockchained-fond and some Onotole :)

                /* config = {
                     "bitshares": "wss://node.gph.ai",
                     "gateway": {
                         "warnMinAmount": 1.5,
                         "okAccumulatedFees": 100,
                         "warnAccountBalance": 100,
                         "warnPoolBalance": 100,
                         "okAccountBalance": 200,
                         "okPoolBalance": 500
                     },
                     "accounts": [
                         [
                             "blckchnd",
                             "GPH",
                             "500.0"
                         ],
                     ],
                     //G5 + Fund
                     "accounts2": [
                         [
                             "gph-bot",
                             "GPH",
                         ],
                         [
                             "bot-gph-eos",
                             "GPH",
                         ],
                         [
                             "bot-bts-gph",
                             "GPH",
                         ],
                         [
                             "bot-trx-gph",
                             "GPH",
                         ],

                     ],
                     //Bots: blockchained-fond
                     "accounts3": [
                         [
                             "blckchnd",
                             "GPH",
                             "not",
                         ],
                         [
                             "golos-bot",
                             "GPH",
                         ],
                         [
                             "ppy-bot",
                             "GPH",
                             false,
                         ],
                         [
                             "bot-nbs-usdt",
                             "GPH",
                         ],
                         [
                             "eos-bts-bot",
                             "GPH",
                         ],
                         [
                             "nbs-bot",
                             "GPH",
                         ],
                         [
                             "monero-bot",
                             "GPH",
                         ],
                         [
                             "steem-bts-bot",
                             "GPH",
                         ],
                         [
                             "golos-usdt-bot",
                             "GPH",
                         ],
                         [
                             "gph-usd-usdt-bot",
                             "GPH",
                             false,
                         ],
                         [
                             "eos-usdt-bot",
                             "GPH",
                         ],
                         [
                             "ltc-usdt-bot",
                             "GPH",
                         ],
                         [
                             "bot-trx-nbs",
                             "GPH",
                             false,
                         ],
                         [
                             "bor-eos-nbs",
                             "GPH",
                         ],
                         [
                             "btc-usdt-bot",
                             "GPH",
                         ],
                         [
                             "bot-btcb-busd",
                             "GPH",
                         ],
                         [
                             "bot-bnb-usdt",
                             "GPH",
                         ],
                         [
                             "bot-busd-usdt",
                             "GPH",
                         ],
                         [
                             "bot-ltc-gph",
                             "GPH",
                         ],
                         [
                             "bot-btcb-btc",
                             "GPH",
                         ],
                         [
                             "monero-bts-bot",
                             "GPH"
                         ],
                         [
                             "tron-bts-bot",
                             "GPH",
                             false,
                         ],
                         [
                             "bot-btc-gph",
                             "GPH",
                         ],

                     ],
                 };*/

                if (config.gateway.warnMinAmount)
                    warnMinAmount = BigNumber(config.gateway.warnMinAmount);
                if (config.gateway.okAccumulatedFees)
                    okAccumulatedFees = BigNumber(config.gateway.okAccumulatedFees);
                if (config.gateway.warnAccountBalance)
                    warnAccountBalance = BigNumber(config.gateway.warnAccountBalance);
                if (config.gateway.warnPoolBalance)
                    warnPoolBalance = BigNumber(config.gateway.warnPoolBalance);
                if (config.gateway.okAccountBalance)
                    okAccountBalance = BigNumber(config.gateway.okAccountBalance);
                if (config.gateway.okPoolBalance)
                    okPoolBalance = BigNumber(config.gateway.okPoolBalance);


                Apis.instance(config.node, true).init_promise.then(() => {
                    ChainStore.init(false).then(() => {

                        this.fetch();
                        this.fetchData1();
                        this.fetchData2();
                        this.fetchData3();

                        this.fetchBEP20_1();
                        //this.fetchBEP20_2();
                    })
                });
            });

    }

    fetch() {

        // Make a request for a user with a given ID
        axios.get(gatewayApi)
            .then((response) => {

                //console.log("response: "+ JSON.stringify(response));

                let tokens = [];
                response.data.forEach(token => {
                    tokens.push({
                        name: token.symbol,
                        minAmount: token.minAmount,
                        depositAllowed: token.depositAllowed,
                        withdrawalAllowed: token.withdrawalAllowed,
                        //withdrawAllow: token.withdrawAllow,
                        withdrawFee: token.withdrawFee || 0,
                        walletType: token.walletType,
                        gatewayWallet: token.gatewayWallet || "WAIT",
                        account: token.issuer,
                    });
                });

                this.fetchTokens(tokens);
                this.fetchPrices(tokens);

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    fetchBEP20_1() {

        // Make a request for a user with a given ID
        axios.get(gatewayApi_bep20)
            .then((response) => {

                //console.log("response: "+ JSON.stringify(response));

                let tokens = [];
                response.data.forEach(token => {
                    tokens.push({
                        name: token.symbol,
                        minAmount: token.minAmount,
                        depositAllowed: token.depositAllowed,
                        withdrawalAllowed: token.withdrawalAllowed,
                        //withdrawAllow: token.withdrawAllow,
                        withdrawFee: token.withdrawFee || 0,
                        walletType: token.walletType,
                        gatewayWallet: token.gatewayWallet || "WAIT",
                        account: token.issuer,
                    });
                });

                this.fetchTokens(tokens);
                this.fetchPrices(tokens);

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }


    //Monitoring Accounts:
    fetchData1() {
        let data1 = this.state.data1;

        //console.log(JSON.stringify(monitor));

        //let vreZka = [["blckchnd", "BTS", "200.0"]];
        monitor = config.accounts1;

        monitor.forEach(item => {
            data1.push({
                id: (item[0] + item[1]),
                account: item[0],
                token: item[1],
                balance: null,
                min: item[2]
            });
        });

        this.setState({data1});

        data1.forEach(item => {
            FetchChain("getAsset", item.token).then(asset => {
                //console.log("asset: "+JSON.stringify(asset));
                FetchChain("getAccount", item.account).then(account => {
                    FetchChain("getObject", account.getIn(["balances", asset.get("id")])).then(balance => {
                        let data1 = this.state.data1;
                        let x = data1.find(i => i.id === item.id);

                        let amount = BigNumber(balance.get("balance")).shiftedBy(-asset.get("precision"));

                        x.balance = {amount, asset: item.token};
                        this.setState({data1});
                    });
                });
            });
        });
    }

    //Monitoring Bots: Monitoring Bots: G5 + Fund
    fetchData2() {
        let data2 = this.state.data2;

        //console.log(JSON.stringify(monitor));

        //let vreZka = [["blckchnd", "BTS", "200.0"]];
        monitor = config.accounts2;

        monitor.forEach(item => {
            data2.push({
                id: (item[0] + item[1]),
                account: item[0],
                token: item[1],
                balance: null,
                status: item[2],
                min: item[2]
            });
        });

        this.setState({data2});

        data2.forEach(item => {
            FetchChain("getAsset", item.token).then(asset => {
                //console.log("asset: "+JSON.stringify(asset));
                FetchChain("getAccount", item.account).then(account => {
                    FetchChain("getObject", account.getIn(["balances", asset.get("id")])).then(balance => {
                        let data2 = this.state.data2;
                        let x = data2.find(i => i.id === item.id);

                        let amount = BigNumber(balance.get("balance")).shiftedBy(-asset.get("precision"));

                        x.balance = {amount, asset: item.token};
                        this.setState({data2});
                    });
                });
            });
        });
    }

    //Monitoring Bots: Fund + some Onotole
    fetchData3() {
        let data3 = this.state.data3;

        //console.log(JSON.stringify(monitor));

        let vreZka = [];
        monitor = config.accounts3;

        monitor.forEach(item => {
            data3.push({
                id: (item[0] + item[1]),
                account: item[0],
                token: item[1],
                balance: null,
                status: item[2],
                min: item[2]
            });
        });

        this.setState({data3});

        data3.forEach(item => {
            FetchChain("getAsset", item.token).then(asset => {
                //console.log("asset: "+JSON.stringify(asset));
                FetchChain("getAccount", item.account).then(account => {
                    FetchChain("getObject", account.getIn(["balances", asset.get("id")])).then(balance => {
                        let data3 = this.state.data3;
                        let x = data3.find(i => i.id === item.id);

                        let amount = BigNumber(balance.get("balance")).shiftedBy(-asset.get("precision"));

                        x.balance = {amount, asset: item.token};
                        this.setState({data3});
                    });
                });
            });
        });
    }

    fetchBEP20_2() {
        let data2 = this.state.data2;

        //console.log(JSON.stringify(monitor));

        //let vreZka = [["blckchnd", "BTS", "200.0"]];
        //monitor = vreZka;

        /*        monitor.forEach(item => {
                    data2.push({
                        id: (item[0] + item[1]),
                        account: item[0],
                        token: item[1],
                        balance: null,
                        min: item[2]
                    });
                });*/

        this.setState({data2});

        data2.forEach(item => {
            FetchChain("getAsset", item.token).then(asset => {
                //console.log("asset: "+JSON.stringify(asset));
                FetchChain("getAccount", item.account).then(account => {
                    FetchChain("getObject", account.getIn(["balances", asset.get("id")])).then(balance => {
                        let data2 = this.state.data2;
                        let x = data2.find(i => i.id === item.id);

                        let amount = BigNumber(balance.get("balance")).shiftedBy(-asset.get("precision"));

                        x.balance = {amount, asset: item.token};
                        this.setState({data2});
                    });
                });
            });
        });
    }

    fetchPrices(tokens) {
        Apis.instance().db_api().exec("get_ticker", ["RUDEX.USDT", "RUDEX.BTS"])
            .then(res => {
                //console.log("usdt: " + JSON.stringify(res));
                const priceBTS = res.latest * 1;

                tokens.forEach(token => {
                    //console.log("a " + JSON.stringify(token));
                    Apis.instance().db_api().exec("get_ticker", ["RUDEX.USDT", token.name])
                        .then(res => {

                            /*                            if( token.name === "RUDEX.PZM"){
                                                            console.log("res " + JSON.stringify(res));
                                                        }*/

                            if (token.name !== "RUDEX.USDT") {
                                let price = res.latest;
                                let x = this.state.prices;
                                x[token.name] = price;
                                this.setState({
                                    prices: x
                                });
                            } else {
                                let price = 1;
                                let x = this.state.prices;
                                x[token.name] = price;
                                this.setState({
                                    prices: x
                                });
                            }


                        });

                    //ДС кросс пересчётом через BTS, альтернатива когда пар больше к нему
                    /*                    Apis.instance().db_api().exec("get_ticker", ["RUDEX.BTS", token.name])
                        .then(res => {


                            /!*                            if( token.name === "RUDEX.PZM"){
                                                            console.log("res " + JSON.stringify(res));
                                                        }*!/

                            if (res.latest === 0) {
                                Apis.instance().db_api().exec("get_ticker", ["RUDEX.USDT", token.name])
                                    .then(res => {
                                        let price = res.latest;
                                        let x = this.state.prices;
                                        x[token.name] = price;
                                        this.setState({
                                            prices: x
                                        });
                                    });
                            }

                            let price = priceBTS * res.latest;
                            let x = this.state.prices;
                            x[token.name] = price;
                            this.setState({
                                prices: x
                            });
                        });*/

                });
            });

    }

    fetchTokens(tokens) {
        let data = this.state.data;

        tokens.forEach(token => {
            data.push({
                token: token.name,
                account: token.account,
                balance: null,
                supply: null,
                feePool: null,
                accumulatedFees: null,
                minAmount: null,
                withdrawFee: null,
                gatewayWallet: null,
                blockchainBalance: null
            });
        });

        this.setState({data});


        tokens.forEach(token => {
            FetchChain("getAsset", token.name).then(asset => {
                FetchChain("getAccount", token.account).then(account => {

                    FetchChain("getObject", account.getIn(["balances", coreToken.id])).then(balance => {
                        let data = this.state.data;
                        let x = data.find(i => i.token === token.name);

                        let amount = BigNumber(balance.get("balance")).shiftedBy(-coreToken.precision);
                        //console.log("amount: "+ amount);
                        let asset = coreToken.symbol;

                        x.account = account.get("name");
                        x.balance = {amount, asset};
                        this.setState({data});
                    });

                    //console.log("asset: "+ asset);
                    FetchChain("getObject", account.getIn(["balances", asset.get("id")])).then(balance => {
                        let data = this.state.data;
                        let x = data.find(i => i.token === token.name);

                        //console.log(JSON.stringify(balance));

                        let amount_gate = BigNumber(balance.get("balance")).shiftedBy(-asset.get("precision"));
                        let asset_gate = asset.get("symbol");
                        let precision = asset.get("precision");

                        x.balance_gate = {amount_gate, asset_gate, precision};
                        this.setState({data});

                    });
                });

                FetchChain("getObject", asset.get("dynamic_asset_data_id"))
                    .then(dynAssetData => {
                        let data = this.state.data;
                        let x = data.find(i => i.token === token.name);
                        x.supply = {
                            amount: BigNumber(dynAssetData.get("current_supply")).shiftedBy(-asset.get("precision")),
                            asset: asset.get("symbol"),
                            precision: asset.get("precision") * 1
                        };
                        x.accumulatedFees = {
                            amount: BigNumber(dynAssetData.get("accumulated_fees")).shiftedBy(-asset.get("precision")),
                            asset: asset.get("symbol")
                        };
                        x.feePool = {
                            amount: BigNumber(dynAssetData.get("fee_pool")).shiftedBy(-coreToken.precision),
                            asset: coreToken.symbol
                        };

                        // minamount
                        x.minAmount = {
                            amount: BigNumber(token.minAmount).shiftedBy(-asset.get("precision")),
                            asset: asset.get("symbol")
                        };

                        // withdraw fee
                        x.withdrawFee = {
                            amount: BigNumber(token.withdrawFee).shiftedBy(-asset.get("precision")),
                            asset: asset.get("symbol")
                        };

                        x.depositAllowed = token.depositAllowed;
                        x.withdrawalAllowed = token.withdrawalAllowed;

                        // gatewayWallet
                        if (token.gatewayWallet !== "WAIT" && token.type !== "list") {
                            //if (token.type === "simple") {

                            //FOR TYPE SIMPLE (some count - example - PRIZM)
                            if (GraphenChains[token.name].hasOwnProperty("addresses")) {
                                x.gatewayWallet = {
                                    //"wallet": token.gatewayWallet,
                                    //"link": GraphenChains[token.name].link.replace("{account}", token.gatewayWallet),
                                    "type": "simple"
                                };
                                let count = 1;
                                GraphenChains[token.name].addresses.forEach(item => {
                                    //console.log("item: " + item);
                                    x.gatewayWallet["wallet" + count] = item;
                                    x.gatewayWallet["link" + count] = GraphenChains[token.name].link.replace("{account}", item);
                                    count += 1;
                                });
                            } else {
                                x.gatewayWallet = {
                                    "wallet": token.gatewayWallet,
                                    "link": GraphenChains[token.name].link.replace("{account}", token.gatewayWallet),
                                    "type": "simple"
                                };
                            }


                        } else {
                            x.gatewayWallet = {
                                "wallet": GraphenChains[token.name].cold_address,
                                "link": GraphenChains[token.name].link,
                                "type": "simple"
                            };

                            if (token.name === "RUDEX.BTC" ||
                                token.name === "RUDEX.GRC" ||
                                token.name === "RUDEX.LTC"
                            ) {
                                x.gatewayWallet = {
                                    type: "list",
                                    blockchain: GraphenChains[token.name].blockchain,
                                    list_addresses: GraphenChains[token.name].list_addresses
                                };
                            }

                            if (token.name === "RUDEX.XMR"
                            ) {
                                x.gatewayWallet = {
                                    "wallet": GraphenChains[token.name].cold_address,
                                    "type": GraphenChains[token.name].type,
                                };
                            }

                        }
                        this.setState({data});
                    })
                    .then(() => {

                        //Get Balances from Wallets ( part of GateAPi )
                        ///bitcoin/balance
                        let requestLine = GraphenChains[token.name].blockchain + "/balance";

                        if (GraphenChains[token.name].hasOwnProperty("addresses")) {

                            GraphenChains[token.name].addresses.forEach(item => {

                                axios.get(balanceApi + requestLine + "/" + item)
                                    .then((response) => {
                                        if (response.data.balance) {
                                            let data = this.state.data;
                                            let x = data.find(i => i.token === token.name);

                                            let init_balance = 0;
                                            try {
                                                if (x.blockchainBalance.balance == null)
                                                    init_balance = 0;
                                                else
                                                    init_balance = x.blockchainBalance.balance;
                                            } catch (e) {
                                                init_balance = 0;
                                            }

                                            //x.blockchainBalance = response.data.balance;
                                            x.blockchainBalance = {
                                                balance: init_balance * 1 + response.data.balance * 1,
                                                supply: BigNumber(x.supply.amount)
                                            };

                                            this.setState({data});
                                        }
                                    })
                                    .catch(function (error) {
                                        // handle error
                                        console.log(error);
                                    });
                            });

                        } else {

                            if (token.walletType === "bsc-tokens") {
                                balanceApi = balanceApi_bep20;
                                let point1 = token.name.indexOf(".");
                                let tokenName = token.name.slice(point1 + 1);
                                //console.log(`tokenName: ${tokenName}`);
                                requestLine = GraphenChains[token.name].blockchain + `/${tokenName}/balance`;
                            }

                            axios.get(balanceApi + requestLine)
                                .then((response) => {
                                    if (response.data.balance) {
                                        let data = this.state.data;
                                        let x = data.find(i => i.token === token.name);

                                        //x.blockchainBalance = response.data.balance;
                                        x.blockchainBalance = {
                                            balance: response.data.balance,
                                            supply: BigNumber(x.supply.amount)
                                        };
                                        this.setState({data});
                                    }
                                })
                                .catch(function (error) {
                                    // handle error
                                    console.log(error);
                                });

                        }


                    })
                    .then(() => {
                        let loading = false;
                        this.setState({loading});
                    });
            });
        });
    }

    render() {

        const {data, prices, loading, data1, data2, data3} = this.state;

        /*            {
                title: 'Token',
                dataIndex: 'token',
                key: 'token',
                render: (val, record, index) => {
                    if (val) {
                        return <a href={`${CONFIG_RUDEX_URL}/asset/${val}`} target="_blank"
                                  rel="noopener noreferrer">
                            {val}
                        </a>;
                    }
                }
            },*/

        const columns = [
/*            {
                title: 'Token',
                dataIndex: 'token',
                key: 'token',
                render: (val, record, index) => {
                    if (val) {
                        return <a href={`${CONFIG_RUDEX_URL}/asset/${val}`} target="_blank"
                                  rel="noopener noreferrer">
                            {val}
                        </a>;
                    }
                }
            },*/
            {
                title: 'Token',
                dataIndex: 'token',
                key: 'token',
                render: (val, record, index) => {
                    if (val) {
                        return <a href={`${CONFIG_RUDEX_URL}/asset/${val}`} target="_blank"
                                  style={{
                                      width: "90px",
                                      display:"block"
                                  }}
                                  rel="noopener noreferrer">
                            <img
                                src={`./img/asset-symbols/${val.toLowerCase()}.png`}
                                style={{
                                    width: "40px"
                                }}
                            />
                            {val.replace("RUDEX.", "")}
                        </a>;
                    }
                }
            },
            {
                title: 'Account',
                dataIndex: 'account',
                key: 'account',
                render: (val, record, index) => {
                    if (val) {
                        return <a href={`${CONFIG_RUDEX_URL}/account/${val}`} target="_blank"
                                  rel="noopener noreferrer">
                            {val}
                        </a>;
                    }
                }
            },
            {
                title: 'Balance (GPH)',
                dataIndex: 'balance',
                key: 'balance',
                render: (val, record, index) => {
                    if (val) {
                        let className = null;
                        if (val.amount.isLessThan(warnAccountBalance)) className = "warn";
                        else if (val.amount.isGreaterThan(okAccountBalance)) className = "success";
                        return <span className={className}>
                            <Asset
                                precision={0}
                                amount={val.amount}
                                asset={val.asset}
                            />
                        </span>;
                    }
                }
            },
            {
                title: 'Fee Pool (GPH)',
                dataIndex: 'feePool',
                key: 'feePool',
                render: (val, record, index) => {
                    if (val) {
                        let className = null;
                        if (val.amount.isLessThan(warnPoolBalance)) className = "warn";
                        else if (val.amount.isGreaterThan(okPoolBalance)) className = "success";
                        return <span className={className}>
                            <Asset
                                precision={0}
                                amount={val.amount}
                                asset={val.asset}
                            />
                        </span>;
                    }
                }
            },
            {
                title: 'Min Deposit/Withdrawal amount',
                dataIndex: 'minAmount',
                key: 'minAmount',
                render: (val, record, index) => {
                    if (val) {

                        let price = prices[val.asset];
                        let priceLabel = null;
                        if (price) {
                            price = BigNumber(price).multipliedBy(val.amount);

                            let priceClassName = null;
                            if (price.isLessThan(warnMinAmount)) priceClassName = "warn";

                            priceLabel = <span className={priceClassName}><small>(${price.toFixed(3)})</small></span>;
                        } else {
                            priceLabel = <span>(?)</span>;
                        }

                        let className = null;
                        return <span className={className}>
                            <Asset
                                amount={val.amount}
                                asset={val.asset}
                            /> {priceLabel}
                        </span>;
                    }
                }
            },
            {
                title: 'Deposit',
                dataIndex: 'depositAllowed',
                key: 'depositAllowed',
                render: (val, record, index) => {
                    if (val === true) {
                        return <span title="Work" style={{cursor: "pointer", paddingLeft: "20px"}}>
                            ✅
                        </span>;
                    } else {
                        return <span title="On maintance" style={{cursor: "pointer", paddingLeft: "20px"}}>
                            ❌
                        </span>;
                    }
                }
            },
            {
                title: 'Withdrawal',
                dataIndex: 'withdrawalAllowed',
                key: 'withdrawalAllowed',
                render: (val, record, index) => {
                    if (val === true) {
                        return <span title="Work" style={{cursor: "pointer", paddingLeft: "20px"}}>
                            ✅
                        </span>;
                    } else {
                        return <span title="On maintance" style={{cursor: "pointer", paddingLeft: "20px"}}>
                            ❌
                        </span>;
                    }
                }
            },
            {
                title: 'Withdraw Fee',
                dataIndex: 'withdrawFee',
                key: 'withdrawFee',
                render: (val, record, index) => {
                    if (val) {

                        let price = prices[val.asset];
                        let priceLabel = null;
                        if (price) {
                            price = BigNumber(price).multipliedBy(val.amount);

                            let priceClassName = null;
                            if (price.isLessThan(warnMinAmount)) priceClassName = "warn";

                            priceLabel = <span className={priceClassName}><small>(${price.toFixed(3)})</small></span>;
                        } else {
                            priceLabel = <span>(?)</span>;
                        }

                        let className = null;
                        return <span className={className}>
                            <Asset
                                amount={val.amount}
                                asset={val.asset}
                            /> {priceLabel}
                        </span>;
                    }
                }
            },
/*            {
                title: 'Accumulated Fees',
                dataIndex: 'accumulatedFees',
                key: 'accumulatedFees',
                render: (val, record, index) => {
                    if (val) {
                        let price = prices[val.asset];
                        let priceLabel = null;
                        if (price) {
                            price = BigNumber(price).multipliedBy(val.amount);

                            let priceClassName = null;
                            if (price.isGreaterThanOrEqualTo(okAccumulatedFees)) priceClassName = "success";

                            priceLabel = <span className={priceClassName}><small>(${price.toFixed(0)})</small></span>;
                        } else {
                            priceLabel = <span>(?)</span>;
                        }

                        return <span>
                                        <Asset
                                            amount={val.amount}
                                            asset={val.asset}
                                        /> {priceLabel}
                                    </span>;
                    }
                }
            },*/
            {
                title: 'Supply',
                dataIndex: 'supply',
                key: 'supply',
                render: (val, record, index) => {
                    if (val) {
                        let price = prices[val.asset];
                        let priceLabel = null;
                        if (price) {
                            price = BigNumber(price).multipliedBy(val.amount).dividedBy(1000);

                            priceLabel = <span><small>(${price.toFixed(0)}K)</small></span>;
                        } else {
                            priceLabel = <span>(?)</span>;
                        }

                        return <span>
                            <Asset
                                amount={val.amount}
                                asset={val.asset}
                            /> {priceLabel}
                        </span>;
                    }
                }
            },
            {
                title: 'Blockchain Address',
                dataIndex: 'gatewayWallet',
                key: 'gatewayWallet',
                render: (val, record, index) => {
                    if (val && val.type === "simple") {
                        //console.log("val: " + JSON.stringify(val));
                        let counter = 1;
                        let loop = true;
                        let listItems;
                        let addresses = [];
                        while (loop) {
                            if (val.hasOwnProperty("link" + counter)) {
                                addresses.push({
                                    "link": val["link" + counter],
                                    "wallet": val["wallet" + counter]
                                });
                                counter += 1;
                            } else {
                                loop = false;
                            }
                        }

                        listItems = addresses.map((address) =>
                            <div>
                                <a href={address.link} target="_blank"
                                   rel="noopener noreferrer">
                                    {address.wallet}
                                </a>
                            </div>
                        );
                        //console.log("listItems: "+ listItems);

                        if (addresses.length === 0) {
                            return <a href={val.link} target="_blank"
                                      rel="noopener noreferrer">
                                {val.wallet}
                            </a>
                        } else {
                            return (
                                listItems
                            );
                        }

                    } else if (val && val.type === "list") {
                        let blockchain_link = "/audit_" + val.blockchain;
                        return <Link to={blockchain_link}>
                            List addresses
                        </Link>;
                    } else if (val && val.type === "private") {
                        return <span>
                            {val.wallet}
                        </span>;
                    }
                }
            },
            {
                title: 'Gate Balance',
                dataIndex: 'balance_gate',
                key: 'balance_gate',
                render: (val, record, index) => {
                    if (val) {
                        return <span>
                            <Asset
                                precision={val.precision}
                                amount={val.amount_gate}
                                asset={val.asset_gate}
                            />
                        </span>;
                    }
                }
            },
            {
                title: 'Blockchain Balance',
                dataIndex: 'blockchainBalance',
                key: 'blockchainBalance',
                render: (val, record, index) => {
                    if (val) {
                        let className = null;
                        let raz = BigNumber(val.balance - val.supply);
                        if (raz < 0) {
                            className = "warn";
                            raz = Math.abs(raz);
                        }
                        else {
                            className = "success";
                            raz = Math.abs(raz);
                        }

                        let priceLabel = null;

                        if (raz < 0) {
                            priceLabel = <span className={className}><small>({raz.toFixed(3)})</small></span>;
                        } else {
                            priceLabel = <span className={className}><small>({raz.toFixed(3)})</small></span>;
                        }

                        return <span>
                            {val.balance} {priceLabel}
                        </span>;
                    }
                }
            },
        ];

        const columns1 = [
            {
                title: '#',
                dataIndex: 'order',
                key: 'order',
                render: (val, record, index) => {
                    return <span>{index + 1}</span>;
                }
            },
            {
                title: 'Account',
                dataIndex: 'account',
                key: 'account',
                render: (val, record, index) => {
                    if (val) {
                        return <a href={`${CONFIG_RUDEX_URL}/account/${val}`} target="_blank"
                                  rel="noopener noreferrer">
                            {val}
                        </a>;
                    }
                }
            }, {
                title: 'Balance',
                dataIndex: 'balance',
                key: 'balance',
                render: (val, record, index) => {
                    if (val) {
                        let className = null;

                        /*                        if (record.min) {
                                                    let min = BigNumber(record.min);
                                                    if (val.amount.isLessThan(min)) className = "warn";
                                                }*/

                        if (val.amount.isLessThan(warnAccountBalance)) className = "warn";


                        return <span className={className}>
                            <Asset
                                amount={val.amount}
                                asset={val.asset}
                            />
                        </span>;
                    }
                }
            }
        ];

        const columns2 = [
            {
                title: '#',
                dataIndex: 'order',
                key: 'order',
                render: (val, record, index) => {
                    return <span>{index + 1}</span>;
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (val, record, index) => {
                    if (val === undefined) {
                        return <span title="Work" style={{cursor: "pointer", paddingLeft: "20px"}}>
                            ✅
                        </span>;
                    } else {
                        return <span title="Pause" style={{cursor: "pointer", paddingLeft: "20px"}}>
                            ❌
                        </span>;
                    }
                }
            },
            {
                title: 'Account',
                dataIndex: 'account',
                key: 'account',
                render: (val, record, index) => {
                    if (val) {
                        return <a href={`${CONFIG_RUDEX_URL}/account/${val}`} target="_blank"
                                  rel="noopener noreferrer">
                            {val}
                        </a>;
                    }
                }
            }, {
                title: 'Balance',
                dataIndex: 'balance',
                key: 'balance',
                render: (val, record, index) => {
                    if (val) {
                        let className = null;

                        /*                        if (record.min) {
                                                    let min = BigNumber(record.min);
                                                    if (val.amount.isLessThan(min)) className = "warn";
                                                }*/

                        let min = BigNumber(10);
                        if (val.amount.isLessThan(min)) className = "warn";


                        return <span className={className}>
                            <Asset
                                amount={val.amount}
                                asset={val.asset}
                            />
                        </span>;
                    }
                }
            }
        ];

        const columns3 = [
            {
                title: '#',
                dataIndex: 'order',
                key: 'order',
                render: (val, record, index) => {
                    return <span>{index + 1}</span>;
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (val, record, index) => {
                    if (val === undefined) {
                        return <span title="Work" style={{cursor: "pointer", paddingLeft: "20px"}}>
                            ✅
                        </span>;
                    } else if (val === "not") {
                        return null;
                    } else {
                        return <span title="Pause" style={{cursor: "pointer", paddingLeft: "20px"}}>
                            ❌
                        </span>;
                    }
                }
            },
            {
                title: 'Account',
                dataIndex: 'account',
                key: 'account',
                render: (val, record, index) => {
                    if (val) {
                        return <a href={`${CONFIG_RUDEX_URL}/account/${val}`} target="_blank"
                                  rel="noopener noreferrer">
                            {val}
                        </a>;
                    }
                }
            },
            {
                title: 'Balance',
                dataIndex: 'balance',
                key: 'balance',
                render: (val, record, index) => {
                    if (val) {
                        let className = null;
                        /*                        if (record.min) {
                                                    let min = BigNumber(record.min);
                                                    if (val.amount.isLessThan(min)) className = "warn";
                                                }*/

                        let min = BigNumber(10);
                        if (val.amount.isLessThan(min)) className = "warn";


                        return <span className={className}>
                            <Asset
                                amount={val.amount}
                                asset={val.asset}
                            />
                        </span>;
                    }
                }
            }
        ];

        return (
            <div>
                <h1>Gateways</h1>
                <Table
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    size="small"
                />

            </div>
        );
    }
}

export default Balances;
