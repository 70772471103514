import React, {Component} from 'react';
import {
    HashRouter as Router,
    Route, //Link
} from 'react-router-dom'

import './App.css';

import Balances from "./components/Balances";
import ListAddresses from "./components/ListAddresses"

import {Layout} from 'antd';

const {Content, Header, Footer} = Layout;

class App extends Component {
    constructor(props) {
        super(props);

        let dateObject = new Date();
        let current_year = dateObject.getFullYear();
        this.state = {
            current_year: current_year
        }
    }
    render() {
        return (
            <Router>
                <Layout className="layout">
                    <Header>
                        <h1 className="header">RuDEX Audit</h1>
                    </Header>
                    <Content style={{padding: "10px"}}>
                        <div>
                            <Route exact path="/" component={Balances}/>
                            <Route exact path="/audit_bitcoin" component={ListAddresses}/>
                            <Route exact path="/audit_litecoin" component={ListAddresses}/>
                            <Route exact path="/audit_gridcoin" component={ListAddresses}/>
                        </div>
                    </Content>
                    <Footer style={{textAlign: 'center', position: "sticky", bottom: "0"}}>
                        <b>RuDEX</b> © 2017-{this.state.current_year}
                    </Footer>
                </Layout>
            </Router>
        );
    }
}

export default App;