import React, {Component} from "react";

import {
    //HashRouter as Router,
    //Route,
    Link
} from 'react-router-dom'

import {Table} from "antd";
import PieChart from "./PieChart"
import axios from "axios";

class ListAddresses extends Component {

    constructor(props) {

        super(props);

        let url = props.location.pathname;
        url = url.substr(url.indexOf('_') + 1, url.length);
        let asset;
        let url_list_addresses;
        let pre_link;

        if (url === "bitcoin") {
            asset = "BTC";
            url_list_addresses = "https://gateway.rudex.org/api/rudex_new/wallets/bitcoin/list_addresses";
            pre_link = "https://www.blockchain.com/btc/address/{address}";

        }
        if (url === "litecoin") {
            asset = "LTC";
            url_list_addresses = "https://gateway.rudex.org/api/rudex_new/wallets/litecoin/list_addresses";
            pre_link = "https://live.blockcypher.com/ltc/address/{address}/";

        } else if (url === "gridcoin") {
            asset = "GRC";
            url_list_addresses = "https://gateway.rudex.org/api/rudex_new/wallets/gridcoin/list_addresses";
            pre_link = "https://gridcoin.ch/balance";

        } else if (url === "prizm") {
            asset = "PZM";
            url_list_addresses = "https://gateway.rudex.org/api/rudex_new/wallets/prizm/list_addresses";
            pre_link = "https://prizm.network/{address}";
        }
        this.state = {
            url: url,
            url_list_addresses: url_list_addresses,
            pre_link: pre_link,
            addresses: [],
            total_balance: 0,
            asset: asset,
            loading: true
        }
    }

    componentDidMount() {
        this.start();
    }

    start() {
        this.fetch();
    }

    //Sort
    //===========================================
    addresses_sort(addresses) {
        addresses.sort(function sortFunction(a, b) {
                if (a.amount > b.amount) {
                    return -1;
                }
                if (a.amount < b.amount) {
                    return 1;
                }
                return 0;
            }
        );
        this.setState({addresses});
    }

    //Delete Dublicates
    //===========================================
    addresses_delete_doubles(addresses) {
        let unical = [];
        for (let ind in addresses) {
            let found_unic = false;
            for (let indInner in unical) {
                //console.log(addresses[ind].address + " - " + unical[indInner].address);
                if (addresses[ind].address === unical[indInner].address) {
                    unical[indInner].address = addresses[ind].address;
                    unical[indInner].amount += addresses[ind].amount * 1;
                    found_unic = true;
                }
            }

            if (!found_unic) {
                unical.push(addresses[ind]);
            }

        }
        addresses = unical;
        //console.log("unical: "+JSON.stringify(unical));
        this.setState({addresses});
        return addresses;
    }

    fetch() {
        const {url_list_addresses} = this.state;

        axios.get(url_list_addresses)
            .then((response) => {

                //Delete Dublicates
                let addresses_raw = this.addresses_delete_doubles(response.data.addresses.addresses);

                //Sort
                this.addresses_sort(addresses_raw);

                let {addresses} = this.state;

                let total_balance = response.data.addresses.balance;
                this.setState({total_balance: total_balance});

                let new_addresses = [];
                addresses.forEach((address, item) => {

                    let addressDst = address.address;
                    let amount = address.amount;

                    if (amount > 0) {
                        let addr_obj = {
                            order: item + 1,
                            address: addressDst,
                            amount: amount
                        };
                        new_addresses.push(addr_obj);

                    }
                    addresses = new_addresses;
                    this.setState({addresses});

                });

            })
            .then(() => {
                let loading = false;
                this.setState({loading});
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    render() {
        const {addresses, total_balance, url, pre_link, asset, loading} = this.state;
        const columns = [
            {
                title: '#',
                dataIndex: 'order',
                key: 'order',
                render: (val, record, index) => {
                    return <span>{val}</span>
                }
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                render: (val, record, index) => {
                    if (val) {
                        let link = pre_link.replace("{address}", val);
                        return <a href={`${link}`} target="_blank"
                                  rel="noopener noreferrer">
                            {val}
                        </a>;
                    }
                }
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (val, record, index) => {
                    if (val) {
                        return <span>{val} {asset}</span>
                    }
                }
            },
        ];
        let title_name = url[0].toUpperCase() + url.substring(1);
        return (
            <div>
                <h1><Link to="/">Gateways</Link> - {title_name} - Addresses</h1>
                <PieChart addresses={addresses} total_balance={total_balance} asset={asset}>
                </PieChart>
                <Table
                    dataSource={addresses}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    size="small"
                />
            </div>
        );
    }
}

export default ListAddresses;
